import React, { useState } from 'react';

import { formatDateTime } from '../../helpers/utils';
import { api } from '../../utils/api';
import { AdmCurrency } from '../../utils/types';
import Txt from '../controls/Txt';
import { AdmInput, AdmTable, AdmTablePopup } from './admin-common';

interface Currency {
  id: string;
  created_at: string;
  is_active: boolean;
  rate_variation: number;
  usd_rate: number;
}

interface Limit {
  currency: string;
  id: number;
  limit_type: 'pay' | 'cpay' | 'sale';
  max_amount: number;
  min_amount: number;
}

interface State {
  rows: Currency[];
  limits: Limit[];
}

export default class Currencies extends React.Component {
  public state: State = {
    rows: [],
    limits: [],
  };

  componentDidMount(): void {
    this.update();
    this.updateLimits();
  }

  update = () => {
    api.admin
      .currencies()
      .then((result) => this.setState({ rows: this.createNewRows(result) }))
      .catch(() => undefined);
  };

  updateLimits = () => {
    api.admin
      .getLimits()
      .then((result) => {
        const limitsByCurrency = result.reduce((acc, item) => {
          acc[item.currency] = acc[item.currency]
            ? {
                ...acc[item.currency],
                [item.limit_type]: { max: item.max_amount, min: item.min_amount },
              }
            : { [item.limit_type]: { max: item.max_amount, min: item.min_amount } };

          return acc;
        }, {});

        this.setState({
          limits: Object.keys(limitsByCurrency).map((currency) => ({
            ...limitsByCurrency[currency],
            currency,
          })),
        });
      })
      .catch(() => undefined);
  };

  onLimitChange = (currency: string, method: string) => (min: number, max: number) => {
    api.admin.patchLimits(method, currency, min, max).finally(this.updateLimits);
  };

  createNewRows = (data: AdmCurrency[]): Currency[] => {
    return data.map((currency) => {
      return {
        id: currency.id.toUpperCase(),
        created_at: currency.created_at,
        is_active: currency.is_active,
        rate_variation: currency.rate_variation * 100 || 0,
        usd_rate: currency.usd_rate || 0,
      };
    });
  };

  updateCurrency = (id: string, key: string, value: number | boolean) => {
    api.admin
      .updateCurrency(id, key, value)
      .then((result) => this.setState({ rows: this.createNewRows(result) }))
      .catch(() => undefined)
      .finally(this.update);
  };

  render(): React.ReactNode {
    const { rows, limits } = this.state;

    return (
      <div className={'adm-page'}>
        <div className={'adm-page-header'}>
          <div className={'adm-page-title'}>
            <Txt k={'admin.currencies.title'} />
          </div>
        </div>
        <div className={'adm-page-content'}>
          <AdmTable
            columns={[
              {
                id: 'id',
                caption: 'admin.currencies.table.id',
              },
              {
                id: 'created_at',
                caption: 'admin.currencies.table.createdAt',
                render: (createdAt) => formatDateTime(new Date(createdAt)),
                minWidth: '11rem',
              },
              {
                id: 'is_active',
                caption: 'admin.currencies.table.status',
                render: (isActive, row) => (
                  <>
                    <span className={`color-${isActive ? 'true' : 'false'}`}>
                      &bull;&nbsp;
                    </span>
                    <Txt
                      k={`admin.api.table.status-${isActive ? 'online' : 'offline'}`}
                    />
                    <CurrencyEnableBox
                      isActive={isActive}
                      onClick={() => this.updateCurrency(row.id, 'is_active', !isActive)}
                    />
                  </>
                ),
              },
              {
                id: 'usd_rate',
                caption: 'admin.currencies.table.usdRate',
                minWidth: '8rem',
              },
            ]}
            rows={rows}
            defaultSortBy={'id'}
          />
        </div>
        <div className={'adm-page-header'}>
          <div className={'adm-page-title'}>
            <Txt k={'admin.currencies.limits'} />
          </div>
        </div>
        <div className={'adm-page-content'}>
          <AdmTable
            columns={[
              {
                id: 'currency',
                caption: 'admin.currencies.table.id',
                render: (currency) => currency.toUpperCase(),
              },
              {
                id: '_',
                caption: 'admin.currencies.table.limit_pay',
                render: (_, record) => (
                  <>
                    <span>
                      Мин. {record.pay.min} - Макс. {record.pay.max}
                    </span>
                    <LimitsBox
                      max={record.pay.max}
                      min={record.pay.min}
                      onClick={this.onLimitChange(record.currency, 'pay')}
                    />
                  </>
                ),
              },
              {
                id: '_',
                caption: 'admin.currencies.table.limit_cpay',
                render: (_, record) => (
                  <>
                    <span>
                      Мин. {record.cpay.min} - Макс. {record.cpay.max}
                    </span>
                    <LimitsBox
                      max={record.cpay.max}
                      min={record.cpay.min}
                      onClick={this.onLimitChange(record.currency, 'cpay')}
                    />
                  </>
                ),
              },
              {
                id: '_',
                caption: 'admin.currencies.table.limit_sale',
                render: (_, record) => (
                  <>
                    <span>
                      Мин. {record.sale.min} - Макс. {record.sale.max}
                    </span>
                    <LimitsBox
                      max={record.sale.max}
                      min={record.sale.min}
                      onClick={this.onLimitChange(record.currency, 'sale')}
                    />
                  </>
                ),
              },
            ]}
            rows={limits}
            defaultSortBy={'id'}
          />
        </div>
      </div>
    );
  }
}

const CurrencyEnableBox = ({
  isActive,
  onClick,
}: {
  isActive: boolean;
  onClick: () => void;
}) => (
  <AdmTablePopup className={'adm-transaction-actions'}>
    <div className={'adm-button delete-transaction'} onClick={onClick}>
      <span>
        <Txt k={`admin.api.table.merchant-${isActive ? 'disable' : 'enable'}`} />
      </span>
    </div>
  </AdmTablePopup>
);

const LimitsBox = ({
  max,
  min,
  onClick,
}: {
  max: number;
  min: number;
  onClick: (min: number, max: number) => void;
}) => {
  const [maxInternal, setMax] = useState(max);
  const [minInternal, setMin] = useState(min);

  return (
    <AdmTablePopup className={'adm-transaction-actions'}>
      <AdmInput
        type="number"
        value={minInternal}
        label={'admin.currencies.table.limit_min'}
        onChange={setMin}
      />
      <AdmInput
        type="number"
        value={maxInternal}
        label={'admin.currencies.table.limit_max'}
        onChange={setMax}
      />
      <div
        className={'adm-button delete-transaction'}
        onClick={() => onClick(Number(minInternal), Number(maxInternal))}>
        <span>
          <Txt k={`admin.currencies.table.save`} />
        </span>
      </div>
    </AdmTablePopup>
  );
};
