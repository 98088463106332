import { isNil } from 'lodash';
import React from 'react';

import { t } from '../../config/i18n';
import { cl, findObjectInList, toFixed } from '../../helpers/utils';
import { api } from '../../utils/api';
import Txt from '../controls/Txt';
import {
  AdmInput,
  AdmSwitcherLabeledChoice,
  AdmText,
  getSettingsCrypto,
  offChangeCrypto,
  onChangeCrypto,
  ReturnButton,
} from './admin-common';
import { ApplyIcon } from './icons';

type RangeBTCFrom =
  | 'BTC_COMM_0.1'
  | 'BTC_COMM_0.001'
  | 'BTC_COMM_0.0005'
  | 'BTC_COMM_0.0001';

type RangeUSDTFrom = 'USDT_COMM_100' | 'USDT_COMM_2';

type WithdrawalCommissions<T extends string> = Record<
  T,
  { value?: number; from: string; to?: string }
>;

const DEFAULT_WITHDRAWAL_BTC_COMMISIONS: WithdrawalCommissions<RangeBTCFrom> = {
  ['BTC_COMM_0.1']: { from: '0.1', to: '1' },
  ['BTC_COMM_0.001']: { from: '0.001', to: '0.1' },
  ['BTC_COMM_0.0005']: { from: '0.0005', to: '0.001' },
  ['BTC_COMM_0.0001']: { from: '0.0001', to: '0.0005' },
};

const DEFAULT_WITHDRAWAL_USDT_COMMISIONS: WithdrawalCommissions<RangeUSDTFrom> = {
  ['USDT_COMM_100']: { from: '100' },
  ['USDT_COMM_2']: { from: '2', to: '100' },
};

interface State {
  crypto: string;
  commissionSky: number;
  amountMin: number;
  network?: number;
  commissionPurchase: number;
  commissionSale: number;
  fastDealFactor: number;
  searchPaymentStrategy: 'all' | 'custom';
  updated: boolean;
  withdrawalBTCCommissions: WithdrawalCommissions<RangeBTCFrom>;
  withdrawalUSDTCommissions: WithdrawalCommissions<RangeUSDTFrom>;
}
export default class Settings extends React.Component {
  public state: State = {
    crypto: getSettingsCrypto(),
    commissionSky: 0,
    amountMin: 0,
    network: 0,
    commissionPurchase: 0,
    commissionSale: 0,
    fastDealFactor: 0,
    searchPaymentStrategy: 'all',
    updated: false,
    withdrawalBTCCommissions: { ...DEFAULT_WITHDRAWAL_BTC_COMMISIONS },
    withdrawalUSDTCommissions: { ...DEFAULT_WITHDRAWAL_USDT_COMMISIONS },
  };

  private changes: {
    cryptoSettings: Record<string, any>;
    commissions: Record<string, any>;
    settings: Record<string, any>;
  } = {
    cryptoSettings: {},
    commissions: {},
    settings: {},
  };

  componentDidMount(): void {
    onChangeCrypto(this.onChangeCrypto);
    this.update();
  }

  componentWillUnmount(): void {
    offChangeCrypto(this.onChangeCrypto);
  }

  onChangeCrypto = (crypto: string) =>
    this.setState({ crypto }, () => {
      this.update();
      this.clearChanges();
    });

  update = () => {
    const { crypto, withdrawalBTCCommissions, withdrawalUSDTCommissions } = this.state;

    Promise.all([api.admin.cryptoSettings(crypto.toLowerCase()), api.admin.settings()])
      .then(([cryptoSettings, settings]) => {
        this.setState({
          commissionSky: cryptoSettings.tx_out_commission,
          amountMin: cryptoSettings.min_tx_amount,
          network: cryptoSettings.net_commission || 0,
          commissionPurchase: cryptoSettings.buyer_commission,
          commissionSale: cryptoSettings.seller_commission,
          fastDealFactor: parseFloat(
            findValue(settings, 'key', 'rate_variation_fast_deal', 'value'),
          ),
          searchPaymentStrategy: findValue(
            settings,
            'key',
            'search_payment_strategy',
            'value',
          ),
          withdrawalBTCCommissions:
            crypto == 'BTC'
              ? settings.reduce((acc: WithdrawalCommissions<RangeBTCFrom>, item) => {
                  if (item.key.includes('BTC_COMM')) {
                    acc[item.key] = {
                      ...withdrawalBTCCommissions[item.key],
                      value: toFixed(+item.value),
                    };
                  }
                  return acc;
                }, withdrawalBTCCommissions)
              : { ...DEFAULT_WITHDRAWAL_BTC_COMMISIONS },
          withdrawalUSDTCommissions:
            crypto == 'USDT'
              ? settings.reduce((acc: WithdrawalCommissions<RangeUSDTFrom>, item) => {
                  if (item.key.includes('USDT_COMM')) {
                    acc[item.key] = {
                      ...withdrawalUSDTCommissions[item.key],
                      value: toFixed(+item.value),
                    };
                  }
                  return acc;
                }, withdrawalUSDTCommissions)
              : { ...DEFAULT_WITHDRAWAL_USDT_COMMISIONS },
        });
      })
      .catch(() => undefined);
  };

  onChange = (category, key, field: string) => (value?: number | string) => {
    const { crypto } = this.state;
    this.changes[category][key] = value;
    this.setState((prevState: State) => ({
      [field]:
        field === `withdrawal${crypto}Commissions`
          ? {
              ...prevState[field],
              [key]: { ...prevState[field][key], value },
            }
          : value,
      updated: true,
    }));
  };

  save = () => {
    const { crypto, updated } = this.state;
    if (!updated) {
      return;
    }

    const promises: Array<Promise<any>> = [];

    for (const key in this.changes.cryptoSettings) {
      if (!isNil(this.changes.cryptoSettings?.[key])) {
        const p = api.admin.updateCryptoSettings(
          crypto.toLowerCase(),
          key,
          String(this.changes.cryptoSettings[key]),
        );
        promises.push(p);
      }
    }
    for (const key in this.changes.settings) {
      if (!isNil(this.changes.settings?.[key])) {
        const p = api.admin.updateSettings(key, String(this.changes.settings[key]));
        promises.push(p);
      }
    }

    if (promises.length > 0) {
      Promise.all(promises)
        .then(() => {
          this.clearChanges();
          alert(t('admin.settings.changes-saved'));
        })
        .catch(() => undefined);
    }
  };

  clearChanges = () => {
    this.changes.cryptoSettings = {};
    this.changes.commissions = {};
    this.changes.settings = {};
    this.setState({ updated: false });
  };

  renderCommissions = () => {
    const { withdrawalBTCCommissions, withdrawalUSDTCommissions, crypto, commissionSky } =
      this.state;

    if (crypto !== 'ETH') {
      const data = Object.entries(
        crypto === 'BTC' ? withdrawalBTCCommissions : withdrawalUSDTCommissions,
      ).map(([key, { from, to, value }]) => {
        const args = to ? [from, to] : [from];
        return (
          <NumberInput
            key={key}
            label={`admin.settings.${to ? 'commission-range' : 'commission-from'}`}
            args={args}
            value={value}
            onChange={this.onChange('settings', key, `withdrawal${crypto}Commissions`)}
          />
        );
      });

      return (
        <>
          <div className={'adm-input-group-btc-commisions'}>
            <AdmText
              k={'admin.settings.commission-sky'}
              args={[crypto]}
              size={'1.1rem'}
            />
          </div>
          {data}
        </>
      );
    }

    if (crypto === 'ETH') {
      <NumberInput
        label={'admin.settings.commission-sky'}
        args={[crypto]}
        value={commissionSky}
        onChange={this.onChange('cryptoSettings', 'tx_out_commission', 'commissionSky')}
      />;
    }
  };

  render(): React.ReactNode {
    const {
      crypto,
      amountMin,
      network,
      commissionPurchase,
      commissionSale,
      fastDealFactor,
      updated,
      searchPaymentStrategy,
    } = this.state;

    return (
      <div className={'adm-page'}>
        <div className={'adm-page-header'}>
          <ReturnButton />
          <div className={'adm-page-title'}>
            <Txt k={'admin.settings.title-full'} args={[crypto]} />
          </div>
        </div>
        <div className={'adm-page-content'}>
          <div className={'adm-input-group'}>
            <div className={'adm-input-group-caption'}>
              <Txt k={'admin.settings.withdraws'} />
            </div>
            {this.renderCommissions()}
            <NumberInput
              label={'admin.settings.amount-min'}
              value={amountMin}
              onChange={this.onChange('cryptoSettings', 'min_tx_amount', 'amountMin')}
            />
            <NumberInput
              label={'admin.settings.network'}
              value={network}
              onChange={this.onChange('cryptoSettings', 'net_commission', 'network')}
            />
          </div>
          <div className={'adm-input-group'}>
            <div className={'adm-input-group-caption'}>
              <Txt k={'admin.settings.deals'} />
            </div>
            <NumberInput
              label={'admin.settings.commission-purchase'}
              value={commissionPurchase}
              onChange={this.onChange(
                'cryptoSettings',
                'buyer_commission',
                'commissionPurchase',
              )}
            />
            <NumberInput
              label={'admin.settings.commission-sale'}
              value={commissionSale}
              onChange={this.onChange(
                'cryptoSettings',
                'seller_commission',
                'commissionSale',
              )}
            />
          </div>
          <div className={'adm-input-group'}>
            <div className={'adm-input-group-caption'}>
              <Txt k={'admin.settings.fast-deal'} />
            </div>
            <NumberInput
              label={'admin.settings.rate-factor'}
              value={fastDealFactor}
              onChange={this.onChange(
                'settings',
                'rate_variation_fast_deal',
                'fastDealFactor',
              )}
            />
          </div>
          <div className={'adm-input-group'}>
            <div className={'adm-input-group-caption'}>
              <Txt k={'admin.settings.common'} />
            </div>
            <div className={'adm-input-control'}>
              <label>
                <Txt k={'admin.settings.search-payment-strategy'} />
              </label>
              <AdmSwitcherLabeledChoice
                value={searchPaymentStrategy}
                label={`admin.strategy.${searchPaymentStrategy}`}
                onChange={this.onChange(
                  'settings',
                  'search_payment_strategy',
                  'searchPaymentStrategy',
                )}
                className={'adm-user-verified'}
                choices={['all', 'custom']}
              />
            </div>
          </div>
          <div className={'adm-input-group'}>
            <div
              className={`adm-action-button save ${cl(!updated, 'disabled')}`}
              onClick={this.save}>
              <ApplyIcon />
              <Txt k={'admin.settings.save'} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const NumberInput = ({
  label,
  value,
  args,
  onChange,
}: {
  label: string;
  args?: Array<string>;
  value?: number;
  onChange: (value?: number) => void;
}) => {
  function change(value?: string) {
    onChange(value ? Number.parseFloat(value) : undefined);
  }

  return (
    <AdmInput type={'number'} args={args} label={label} value={value} onChange={change} />
  );
};

function findValue<T>(
  list: T[],
  key: keyof T,
  keyValue: any,
  valueName: keyof T,
): any | undefined {
  const obj = findObjectInList(list, key, keyValue);
  return obj ? obj[valueName] : undefined;
}
